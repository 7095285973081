@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
html::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
html::-webkit-scrollbar-thumb {
  background: #007078;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
html::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #e3a835, #e3a835 1px, #e3a835 0, #e3a835);
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
html::-webkit-scrollbar-corner {
  background: #e3a835;
}

body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
body.active {
  overflow: hidden;
}

ul {
  padding: 0;
  margin-bottom: 0;
}
ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  color: #1d1d1f;
  padding: 0;
  margin-bottom: 0;
}

button {
  border: 0;
}
button:focus {
  outline: none;
  box-shadow: none;
}

p,
span,
strong,
b,
a,
li,
input {
  color: #5d5d5d;
  padding: 0;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 670px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 882px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1095px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}
.sliderContainer {
  padding: 0;
}
.sliderContainer .slick-slider .slick-list {
  margin: 0 -10px;
  padding: 1rem 0;
}
.sliderContainer .slick-slider .slick-list .slick-track .slick-slide {
  padding: 0 10px;
}
.sliderContainer .slick-slider .slick-list .slick-track .slick-slide .ddd {
  position: relative;
}
.sliderContainer .slick-slider .slick-dots {
  bottom: -40px;
}
.sliderContainer .slick-slider .slick-dots li.slick-active button {
  background-color: #ffa700;
}
.sliderContainer .slick-slider .slick-dots li button {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5rem;
  padding: 0;
  background-color: #135257;
}
.sliderContainer .slick-slider .slick-dots li button::before {
  content: none;
}

.sectionTitle {
  font-size: 3rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 3rem;
}
.sectionTitle .highlightText {
  color: #ffa700;
}
@media (max-width: 767px) {
  .sectionTitle {
    font-size: 2.4rem;
  }
}

.commonPara {
  font-size: 1.6rem;
  font-weight: 400;
  color: #656565;
}
@media (max-width: 1024px) {
  .commonPara {
    font-size: 1.4rem;
  }
}

.dealBox {
  width: -moz-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.dealBox span {
  font-size: 1.6rem;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .dealBox span {
    font-size: 1.4rem;
  }
}

#popularProductsSection {
  padding: 8rem 0 10rem;
  background-color: #f8f8f8;
}
@media (max-width: 834px) {
  #popularProductsSection {
    padding: 8rem 2rem 10rem;
  }
}
@media (max-width: 767px) {
  #popularProductsSection {
    padding: 4rem 1rem 5rem;
  }
}
#popularProductsSection .sectionTitle {
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  #popularProductsSection .sectionTitle {
    text-align: center;
  }
}
#popularProductsSection .commonPara {
  width: 45%;
}
@media (max-width: 767px) {
  #popularProductsSection .commonPara {
    width: 100%;
  }
}
#popularProductsSection .parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-top: 3rem;
}
@media (max-width: 767px) {
  #popularProductsSection .parent {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
}
#popularProductsSection .parent .gridBox {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 2rem;
  border-radius: 0.6rem;
}
#popularProductsSection .parent .wrap h4 {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.5rem;
}
@media (max-width: 1024px) {
  #popularProductsSection .parent .wrap h4 {
    font-size: 1.6rem;
  }
}
#popularProductsSection .parent .wrap.pose3 {
  position: absolute;
  top: 10%;
  right: 5%;
  height: 80% !important;
}
#popularProductsSection .parent .wrap.pose3 .commonPara {
  width: 100% !important;
}
#popularProductsSection .parent .wrap.pose1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#popularProductsSection .parent .wrap.pose1 .commonPara {
  width: 70%;
  color: #d9d9d9;
}
@media (max-width: 1024px) {
  #popularProductsSection .parent .wrap.pose1 .commonPara {
    font-size: 1.2rem;
  }
}
#popularProductsSection .parent .div1 {
  grid-area: 1/1/3/2;
  background-image: url(../../assets/images/grid1.png);
  height: 40.1rem;
}
@media (max-width: 767px) {
  #popularProductsSection .parent .div1 {
    grid-area: 1/1/2/2;
    height: 22rem;
  }
}
#popularProductsSection .parent .div2 {
  grid-area: 1/2/2/3;
  background-image: url(../../assets/images/grid2.png);
}
@media (max-width: 767px) {
  #popularProductsSection .parent .div2 {
    grid-area: 2/1/3/2;
  }
}
#popularProductsSection .parent .div3 {
  grid-area: 2/2/3/3;
  background-image: url(../../assets/images/grid3.png);
}
@media (max-width: 767px) {
  #popularProductsSection .parent .div3 {
    grid-area: 3/1/4/2;
  }
}

#bestDealSection {
  padding: 0rem 0 11rem;
}
@media (max-width: 834px) {
  #bestDealSection {
    padding: 0rem 2rem 11rem;
  }
}
@media (max-width: 767px) {
  #bestDealSection {
    padding: 0rem 1rem 0rem;
  }
}
#bestDealSection .dealWrap {
  background-image: url(../../assets/images/bestdealBg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 22.6rem;
  width: 100%;
  position: relative;
  padding: 2rem 6rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #bestDealSection .dealWrap {
    padding: 2rem 2rem;
  }
}
#bestDealSection .dealWrap h5 {
  font-size: 3.64rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.5rem;
  width: 45%;
}
@media (max-width: 1024px) {
  #bestDealSection .dealWrap h5 {
    width: 55%;
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  #bestDealSection .dealWrap h5 {
    width: 100%;
    font-size: 2.2rem;
  }
}
#bestDealSection .dealWrap p {
  font-size: 2.2rem;
  font-weight: 400;
  color: #ffa700;
}
@media (max-width: 1024px) {
  #bestDealSection .dealWrap p {
    font-size: 2rem;
  }
}

.emptyPage {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#filterPage {
  padding: 6rem 0;
}
@media (max-width: 767px) {
  #filterPage {
    padding: 2rem 0 4rem;
  }
}
#filterPage .form-select {
  height: 4.5rem;
  border: 0.2rem solid #007078;
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}
#filterPage .form-select:focus {
  outline: none;
  box-shadow: none;
}
@media (max-width: 834px) {
  #filterPage .row {
    row-gap: 1.5rem;
  }
}
#filterPage .filter-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 4rem 0 4rem;
  padding-bottom: 4rem;
  border-bottom: 0.1rem solid #ccc;
}
@media (max-width: 834px) {
  #filterPage .filter-list {
    margin: 4rem 0 2rem;
    padding-bottom: 2rem;
  }
}
#filterPage .filter-list .filterTitle {
  height: 4.5rem;
  display: flex;
  align-items: center;
}
#filterPage .filter-list .filterTitle p .commonPara {
  font-size: 1.6rem;
  font-weight: 400;
  color: #656565;
}
@media (max-width: 1024px) {
  #filterPage .filter-list .filterTitle p .commonPara {
    font-size: 1.4rem;
  }
}
#filterPage .filter-list .filterTitle .commonPara {
  font-size: 1.8rem;
  font-weight: 600;
}
@media (max-width: 834px) {
  #filterPage .filter-list .filterTitle .commonPara {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  #filterPage .filter-list .filterTitle .commonPara {
    font-size: 1.2rem;
  }
}
#filterPage .filter-list .menusWraps {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: 4.5rem;
}
@media (max-width: 767px) {
  #filterPage .filter-list .menusWraps {
    gap: 0.5rem;
    height: 3.5rem;
  }
}
#filterPage .filter-list .menusWraps .menuBoxItem {
  font-size: 1.6rem;
  font-weight: 500;
  color: #5b5b5b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
}
@media (max-width: 834px) {
  #filterPage .filter-list .menusWraps .menuBoxItem {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  #filterPage .filter-list .menusWraps .menuBoxItem {
    font-size: 1.2rem;
    height: 3.5rem;
  }
}
#filterPage .filter-list .menusWraps .menuBoxItem:hover {
  background-color: #007078;
  color: #fff;
}
#filterPage .filter-list .menusWraps .menuBoxItem.active {
  background-color: #007078;
  color: #fff;
}
#filterPage .filter-list .allBtn {
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 834px) {
  #filterPage .filter-list .allBtn {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  #filterPage .filter-list .allBtn .de {
    display: none;
  }
}
#filterPage .filterdItemsListing .ddd {
  position: relative;
}
#filterPage .selectedMenusWrap {
  border-bottom: 0.1rem solid #ccc;
  margin-bottom: 4rem;
}
#filterPage .selectedMenusWrap .selectedMenusRows {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  margin-bottom: 4rem;
}
@media (max-width: 834px) {
  #filterPage .selectedMenusWrap .selectedMenusRows {
    margin-bottom: 2rem;
  }
}
#filterPage .selectedMenusWrap .selectedMenusRows .selectedBox {
  font-size: 1.6rem;
  font-weight: 500;
  color: #5b5b5b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  background-color: #007078;
  color: #fff;
}
@media (max-width: 834px) {
  #filterPage .selectedMenusWrap .selectedMenusRows .selectedBox {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  #filterPage .selectedMenusWrap .selectedMenusRows .selectedBox {
    font-size: 1.2rem;
    height: 3.5rem;
  }
}
#filterPage .selectedMenusWrap .selectedMenusRows .clearBtn {
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: red;
  color: #fff;
  gap: 1rem;
}
@media (max-width: 834px) {
  #filterPage .selectedMenusWrap .selectedMenusRows .clearBtn {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  #filterPage .selectedMenusWrap .selectedMenusRows .clearBtn {
    font-size: 1.2rem;
    height: 3.5rem;
  }
}
@media (max-width: 834px) {
  #filterPage .selectedMenusWrap .selectedMenusRows .clearBtn i {
    font-size: 1.6rem;
  }
}

#mainProductListSection {
  padding-top: 8rem;
}
#mainProductListSection .sectionTitle {
  text-align: center;
}
#mainProductListSection .row {
  row-gap: 1.5rem;
}
#mainProductListSection .wraps {
  border: 0.1rem solid #007078;
  border-radius: 0.5rem;
  background-color: #eef7ff;
  padding: 1rem;
}
#mainProductListSection .wraps .typeText {
  padding-top: 1.2rem;
}
#mainProductListSection .wraps .typeText h6 {
  font-size: 1.8rem;
  color: #1c2c2d;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}/*# sourceMappingURL=gobal.css.map */