#faqSection {
  position: relative;
  margin: 2rem 0 11rem;
}
@media (max-width: 834px) {
  #faqSection {
    margin: 2rem 2rem 11rem;
  }
}
@media (max-width: 767px) {
  #faqSection {
    padding: 4rem 1rem 0rem;
    margin: 2rem 1rem 4rem;
  }
}
#faqSection .bgImg {
  position: relative;
}
@media (max-width: 767px) {
  #faqSection .bgImg .col-md-7 {
    display: none;
  }
}
#faqSection .bgImg .rightBox {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50%;
  transform: translateY(-50%);
  background-color: #f7f7fb;
  border-radius: 0.5rem;
  padding: 4rem 4rem 0;
}
@media (max-width: 834px) {
  #faqSection .bgImg .rightBox {
    width: 65%;
  }
}
@media (max-width: 767px) {
  #faqSection .bgImg .rightBox {
    width: 100%;
    padding: 4rem 1rem 0;
    position: relative;
    right: unset;
    top: unset;
    transform: none;
  }
}
#faqSection .accordionWrap {
  z-index: 1;
}
#faqSection .accordionWrap .sectionTitle {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  #faqSection .accordionWrap .sectionTitle {
    text-align: center;
  }
}
#faqSection .accordianContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 3.1rem 0;
  position: relative;
}
#faqSection .accordianContainer .commonAccordian {
  border: 0.2rem solid #fff;
  transition: all 0.3s ease;
  border-radius: 1.2rem;
  background: #ffffff;
  margin: 0.6rem 0;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.1);
}
#faqSection .accordianContainer .commonAccordian .accordianBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.2rem;
  cursor: pointer;
  height: 5.5rem;
  padding: 0 2.5rem;
  transition: height 0.5s ease;
}
#faqSection .accordianContainer .commonAccordian .accordianBtn .acc-title {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
  color: #656565;
  margin-bottom: 0;
  width: 80%;
  line-height: 1.5;
}
@media (max-width: 1024px) {
  #faqSection .accordianContainer .commonAccordian .accordianBtn .acc-title {
    font-size: 1.6rem;
  }
}
#faqSection .accordianContainer .commonAccordian .accordianBtn span {
  display: flex;
  width: 2.6rem;
  height: 2.6rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform 0.3s linear;
  border-radius: 100%;
  box-shadow: 0 0 11px -4px #b0b0b0;
}
#faqSection .accordianContainer .commonAccordian .accordianBtn span svg {
  height: 1.4rem;
}
#faqSection .accordianContainer .commonAccordian .accordianBtn span svg path {
  stroke: #01557d;
}
#faqSection .accordianContainer .commonAccordian .accordianContent {
  transition: height 0.8s linear;
  height: 0px;
  overflow: hidden;
}
#faqSection .accordianContainer .commonAccordian .accordianContent .accordianText {
  padding: 0 2.5rem 2.5rem 2.5rem;
}
#faqSection .accordianContainer .commonAccordian .accordianContent .accordianText p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #656565;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  #faqSection .accordianContainer .commonAccordian .accordianContent .accordianText p {
    font-size: 1.4rem;
  }
}
#faqSection .accordianContainer .commonAccordian.active {
  border-color: #01557d;
}
#faqSection .accordianContainer .commonAccordian.active .accordianArrow {
  transform: rotate(90deg);
  background-color: #01557d;
}
#faqSection .accordianContainer .commonAccordian.active .accordianArrow svg path {
  stroke: white;
}
#faqSection .accordianContainer .commonAccordian.active .accordianContent {
  height: auto;
}/*# sourceMappingURL=accordion.css.map */