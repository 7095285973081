@import "../../assets/scss/mixin";
@import "../../assets/scss/media";
#whoWeAreSection {
  padding: 8rem 0;
  @include x-767-tablet{
    padding: 2rem 0 4rem;
  }
  .leftBox {
    @include commonPara;
    .commonPara {
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: #888888;
    }
    .sectionTitle {
      margin-bottom: 2rem;
    }
    .contentWrap {
      .commonPara {
        font-weight: 400;
        margin-bottom: 1.5rem;
        color: #8c8c8c;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .rightBox {
    position: relative;
    height: 100%;
    width: 100%;
    @include md-tablet{
      display: none;
    }
    span {
      position: absolute;
    }
    .topRight {
      top: 0;
      right: 0;
    }
    .bottomLeft {
      bottom: 0;
      left: 0;
    }
    .topLeft {
      top: 0;
      left: 20%;
      z-index: -1;
    }
    .topLeftMid {
      position: absolute;
      top: 10%;
      left: 20%;
    }
    .bottomRightMid {
      position: absolute;
      bottom: 10%;
      left: 47%;
    }
    .box {
      width: fit-content;
      height: 5.5rem;
      background-color: #fff;
      padding: 0 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      border-radius: 0.5rem;
      box-shadow: 0 0 6px -1px #a19b9b;
      .smallBox {
        width: 4.2rem;
        height: 4.2rem;
        background-color: #fef5f5;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .contentBox {
        h6 {
          font-size: 1.6rem;
          font-weight: 500;
          color: #94a3b8;
        }
        p{
            font-size: 1.6rem;
            font-weight: 700;
            color: #007078;
        }
      }
    }
  }
}
