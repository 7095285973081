@import "./media";

@mixin squareBtn {
  .actionBtn {
    width: fit-content;
    height: 4.5rem;
    border-radius: 0.5rem;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1;
    @include d-desktop {
      font-size: 1.4rem;
    }
    
    .iconSvgWrap{
      svg{
        @include x-767-tablet {
          height: 1.4rem;
        }
      }
    }
    &:focus {
      background-color: #00575d !important;
    }
    &:hover {
      background-color: #00575d !important;
      color: #fff !important;
      span {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .iconSvgWrap {
      &.hamb {
        .cartIco {
          display: none;
        }
      }

      &.cartIcon {
        .hamburger {
          display: none;
        }
      }
    }
  }
}

@mixin roundBtn {
  .linkBtn {
    display: flex;
    border: 0.1rem solid #e3a835;
    height: 4.5rem;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem 1.52rem;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    font-size: 1.6rem;
    font-weight: 600;
    gap: 1rem;
    line-height: 0;
    @include d-desktop {
      font-size: 1.4rem;
    }
    .arrow {
      &.transArrow {
        display: none;
      }
    }
  }
}

@mixin commonPara {
  .commonPara {
    font-size: 1.6rem;
    font-weight: 400;
    color: #656565;
    @include d-desktop {
      font-size: 1.4rem;
    }
  }
}

@mixin commonTitle {
  .sectionTitle {
    font-size: 3rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 3rem;
    .highlightText {
      color: #ffa700;
    }
    @include x-767-tablet {
      font-size: 2.4rem;
    }
  }
}

@mixin commonSlider {
  .sliderContainer {
    padding: 0;
    .slick-slider {
      .slick-list {
        margin: 0 -10px;
        padding: 1rem 0;
        .slick-track {
          .slick-slide {
            padding: 0 10px;
            .ddd {
              position: relative;
            }
          }
        }
      }
      .slick-dots {
        bottom: -40px;
        li {
          &.slick-active {
            button {
              background-color: #ffa700;
            }
          }
          button {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 5rem;
            padding: 0;
            background-color: #135257;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}

@mixin commonSpanBox {
  .dealBox {
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    span {
      font-size: 1.6rem;
      font-weight: 600;
      @include d-desktop {
        font-size: 1.4rem;
      }
    }
  }
}

@mixin commonSelect {
  .form-select {
    height: 4.5rem;
    border: 0.2rem solid #007078;
    padding-left: 1.5rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
