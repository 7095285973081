#singleProductPage {
  padding: 8rem 0;
}
#singleProductPage .row {
  row-gap: 1.5rem;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 5rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
}

.rightBox {
  height: 100%;
  padding-left: 3rem;
}
@media (max-width: 767px) {
  .rightBox {
    padding-left: 0;
  }
}
.rightBox h4 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 0.5rem;
}
.rightBox p {
  font-size: 1.8rem;
  font-weight: 400;
  color: #656565;
  margin-bottom: 1rem;
}
.rightBox .qtBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2rem;
}
.rightBox .qtBox button {
  width: -moz-fit-content;
  width: fit-content;
  height: 4.5rem;
  border-radius: 0.5rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background-color: #007078;
}
.rightBox .qtBox button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rightBox .qtBox button:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.rightBox .qtBox button span {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.8rem;
}
.rightBox .qtBox .qtCountBox {
  font-weight: 600;
  font-size: 2rem;
  color: #000;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  border-top: 0.1rem solid #007078;
  border-bottom: 0.1rem solid #007078;
  width: 6rem;
}
.rightBox .btnsWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
}
.rightBox .descriptionBox h4 {
  font-size: 2rem;
}
.rightBox .descriptionBox p {
  font-size: 1.6rem;
  text-align: justify;
}

#specificationSection {
  margin-top: 4rem;
}
#specificationSection .nav-pills {
  border-bottom: 0.1rem solid #ccc;
  padding-bottom: 1rem;
  gap: 1rem;
}
#specificationSection .nav-pills .nav-item .nav-link {
  font-size: 1.6rem;
  font-weight: 500;
  color: #5b5b5b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
}
#specificationSection .nav-pills .nav-item .nav-link.active, #specificationSection .nav-pills .nav-item .nav-link:hover {
  background-color: #007078;
  color: #fff;
}
#specificationSection .tab-content {
  padding: 1.5rem 0;
}
#specificationSection .tab-content .table-responsive .table {
  width: 50%;
}
@media (max-width: 834px) {
  #specificationSection .tab-content .table-responsive .table {
    width: 100%;
  }
}
#specificationSection .tab-content .table-responsive .table tr:nth-child(odd) {
  background-color: #efefef;
}
#specificationSection .tab-content .table-responsive .table tr td {
  padding: 1rem 1rem;
}
#specificationSection .tab-content .table-responsive .table tr td:first-child {
  font-size: 1.6rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  #specificationSection .tab-content .table-responsive .table tr td:first-child {
    font-size: 1.4rem;
  }
}
#specificationSection .tab-content .table-responsive .table tr td:last-child {
  font-size: 1.6rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  #specificationSection .tab-content .table-responsive .table tr td:last-child {
    font-size: 1.2rem;
  }
}
#specificationSection .tab-content .downloadListWrap .downloadUl {
  padding-left: 3.5rem;
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  list-style: decimal;
}
@media (max-width: 767px) {
  #specificationSection .tab-content .downloadListWrap .downloadUl .mainList {
    font-size: 1.6rem;
  }
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList:last-child {
  margin-bottom: 0;
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList ul {
  padding-left: 3.5rem;
  margin-top: 0.5rem;
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList ul .subList {
  margin-bottom: 0.8rem;
  list-style: circle;
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList ul .subList:last-child {
  margin-bottom: 0;
}
#specificationSection .tab-content .downloadListWrap .downloadUl .mainList ul .subList a {
  font-size: 1.6rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  #specificationSection .tab-content .downloadListWrap .downloadUl .mainList ul .subList a {
    font-size: 1.4rem;
  }
}/*# sourceMappingURL=details.css.map */