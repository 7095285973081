@import "../../../assets/scss/media";

.navbar {
  width: 100%;
  .navbar-toggler {
    border: 0;
    background: transparent;
    width: 3.8rem;
    height: 3.8rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.custNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  .navLeftBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-brand{
      img{
        @include md-tablet{
          width: 90%;
        }
        @include x-767-tablet{
          width: 80%;
        }
      }
    }
  }
  .col-md-3 {
    @include d-desktop {
      width: 33.333%;
    }
    @include md-tablet {
      width: 45%;
    }
    @include x-767-tablet {
      width: 80%;
    }
  }
  .col-md-5 {
    @include d-desktop {
      display: none;
    }
  }

  .navbar-collapse {
    .navbar-nav {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      @include d-desktop {
        margin: 0 0 auto auto;
        width: 60%;
      }
      @include md-tablet {
        width: 100%;
      }
      .nav-item {
        &.cartMenu {
          &::after {
            content: "";
            position: absolute;
            top: 25%;
            right: 4%;
            width: 0.2rem;
            height: 55%;
            background: #cbcbcb;
            @include d-desktop {
              right: 5.2%;
            }
            @include mid-991-tab {
              content: none;
            }
          }
        }
        .nav-link {
          font-size: 1.6rem;
          font-weight: 500;
          color: #919191;
          position: relative;
          padding: 0;
          @include d-desktop {
            font-size: 1.4rem;
          }
          .cartBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            background: #007078;
            border-radius: 5rem;
            position: relative;
          }
          .dotSpan {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 1rem;
            height: 1rem;
            border-radius: 5rem;
            background: #27ed8e;
          }
        }
      }
    }
  }
}

.allProductsDisplayBox {
  background-color: #f8f8f8;
  position: absolute;
  width: 90.5%;
  height: 53.9rem;
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: 0px 0px 6px -2px #000;
  @include md-tablet {
    width: 100%;
    height: 100%;
    min-height: 50rem;
    overflow: auto;
  }
  .tabsWrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    @include x-767-tablet {
      flex-direction: column;
      padding-top: 4rem;
    }
    .closeBtn {
      position: absolute;
      right: 2.1rem;
      top: 1rem;
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-pills {
      // flex-direction: column;
      width: 20%;
      height: 100%;
      max-height: 53.9rem;
      overflow-y: auto;
      gap: 0.5rem;
      @include md-tablet {
        width: 30%;
      }
      @include x-767-tablet {
        width: 100%;
      }
      &::-webkit-scrollbar {
        width: 16px;
        height: 16px;
      }
      &::-webkit-scrollbar-thumb {
        background: #007078;
        border-radius: 16px;
        box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
          inset -2px -2px 2px rgba(0, 0, 0, 0.25);
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(
          90deg,
          #e3a835,
          #e3a835 1px,
          #e3a835 0,
          #e3a835
        );
        box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
          inset -2px -2px 2px rgba(0, 0, 0, 0.25);
      }
      &::-webkit-scrollbar-corner {
        background: #e3a835;
      }
      .nav-link {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 2rem;
        height: 7.7rem;
        border-radius: 0;
        border: 0;
        font-size: 1.8rem;
        font-weight: 600;
        color: #000000;
        position: relative;
        background-color: #ebebeb;
        @include md-tablet {
          font-size: 1.8rem;
          height: 5.5rem;
          justify-content: center;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 0.6rem;
          height: 100%;
          background-color: #dddddd;
        }
        &.active {
          background-color: #007078;
          color: #f8f8f8;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0.6rem;
            height: 100%;
            background-color: #ffa700;
          }
        }
        &:hover {
          background-color: #007078;
          color: #fff;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0.6rem;
            height: 100%;
            background-color: #ffa700;
          }
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1.5rem;
          background-color: #f1f1f1;
          border-radius: 0.5rem;
          @include x-767-tablet {
            display: none;
          }
          img {
            height: 6rem;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .tab-content {
      width: 80%;
      height: 100%;
      padding: 2rem 3rem 0 1rem;
      max-height: 53.9rem;
      overflow-y: auto;
      @include x-767-tablet {
        width: 100%;
        padding: 2rem 1rem 0 1rem;
      }
      &::-webkit-scrollbar {
        width: 16px;
        height: 16px;
      }
      &::-webkit-scrollbar-thumb {
        background: #007078;
        border-radius: 16px;
        box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
          inset -2px -2px 2px rgba(0, 0, 0, 0.25);
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(
          90deg,
          #e3a835,
          #e3a835 1px,
          #e3a835 0,
          #e3a835
        );
        box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
          inset -2px -2px 2px rgba(0, 0, 0, 0.25);
      }
      &::-webkit-scrollbar-corner {
        background: #e3a835;
      }
    }
  }
  &.show {
    left: 50%;
    transition: left 0.4s ease;
  }
  &.hide {
    left: -50%;
    transition: left 0.4s ease;
  }
}

#navbarTogglerDemo02 {
  &.navbar-collapse {
    @include mid-991-tab {
      background: #171a26;
      box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
      display: block;
      height: 100%;
      left: -100%;
      overflow-y: auto;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 30px;
      position: fixed;
      top: 0;
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
      width: 50%;
      z-index: 999999;
    }
    @include mid-991-tab {
      width: 80%;
    }
    &.show {
      left: 0;
      .navbar-nav {
        .nav-item {
          padding-bottom: 1.5rem;
          &:last-child {
            border-bottom: 0;
          }
          .nav-link {
            justify-content: space-between;
            font-size: 2rem;
            color: unset;
            position: relative;
            &:hover {
              color: #2276b9;
              @include mid-991-tab {
                color: #fff;
              }
            }
            @include mid-991-tab {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.pagination {
  padding: 2rem 0;
  justify-content: flex-end;
  gap: 1rem;
  button {
    background-color: #007078d0;
    color: #f8f8f8;
    padding: 0.5rem 1.5rem;
    width: fit-content;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    &.active {
      background-color: #004f54;
      color: #f8f8f8;
    }
    &:hover {
      background-color: #007078;
      color: #f8f8f8;
    }
  }
}
