@import "../../assets/scss/media";
#expertiseSection {
  background-color: #007078;
  padding: 8rem 0;
  @include x-767-tablet{
    padding: 4rem 0;
  }
  .sectionTitle {
    text-align: center;
    color: #fff;
    margin-bottom: 1rem;
  }
  .commonPara {
    text-align: center;
    width: 35%;
    margin: 0 auto;
    color: #d9d9d9;
    @include x-767-tablet{
      width: 100%;
    }
  }
  .expertiseGrid {
    margin-top: 3rem;
    .parent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 2rem;
      @include x-767-tablet {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
      }
      .gridBox {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          height: 100%;
          border-radius: 0.4rem;
        }
        .overlayText {
          position: absolute;
          h3 {
            font-size: 2.4rem;
            font-weight: 600;
            color: #fff;
            margin-bottom: 1.5rem;
            @include md-tablet{
              font-size: 2rem;
            }
          }
          p {
            font-size: 1.6rem;
            font-weight: 500;
            color: #fff;
            @include md-tablet{
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
      background-image: url(../../assets/images/egrid1.png);
      height: 18.9rem;
      @include x-767-tablet {
        grid-area: 1 / 1 / 2 / 2;
      }
      &::before {
        left: 0;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 167, 0, 1) 0%,
          rgba(16, 12, 8, 0.241421568627451) 80%
        );
      }
      .overlayText {
        bottom: 15%;
        width: 80%;
        left: 5%;
        z-index: 1;
      }
    }
    .div2 {
      grid-area: 2 / 1 / 3 / 2;
      background-image: url(../../assets/images/egrid2.png);
      @include x-767-tablet {
        grid-area: 2 / 1 / 3 / 2;
      }
      &::before {
        right: 0;
        width: 100%;
        background: linear-gradient(90deg, #ffa700 0%, #100c083d 80%);
        transform: rotate(-180deg);
      }
      .overlayText {
        bottom: 15%;
        width: 80%;
        right: 5%;
        z-index: 1;
        text-align: right;
      }
    }
    .div3 {
      grid-area: 1 / 2 / 3 / 3;
      background-image: url(../../assets/images/egrid3.png);
      @include x-767-tablet {
        grid-area: 3 / 1 / 4 / 2;
      }
      &::before {
        left: 0;
        width: 100%;
        background: linear-gradient(
          0deg,
          rgba(255, 167, 0, 1) 0%,
          rgba(0, 0, 0, 0) 75%
        );
      }
      .overlayText {
        bottom: 7%;
        width: 85%;
        right: 10%;
        z-index: 1;
      }
    }
  }
}
