@import "../../assets/scss/media";
#faqSection {
  position: relative;
  margin: 2rem 0 11rem;
  @include md-tablet {
    margin: 2rem 2rem 11rem;
  }
  @include x-767-tablet {
    padding: 4rem 1rem 0rem;
    margin: 2rem 1rem 4rem;
  }

  .bgImg {
    position: relative;
    .col-md-7 {
      @include x-767-tablet {
        display: none;
      }
    }
    .rightBox {
      position: absolute;
      right: 0;
      top: 50%;
      width: 50%;
      transform: translateY(-50%);
      background-color: #f7f7fb;
      border-radius: 0.5rem;
      padding: 4rem 4rem 0;
      @include md-tablet {
        width: 65%;
      }
      @include x-767-tablet {
        width: 100%;
        padding: 4rem 1rem 0;
        position: relative;
        right: unset;
        top: unset;
        transform: none;
      }
    }
  }
  .accordionWrap {
    z-index: 1;
    .sectionTitle {
      margin-bottom: 0;
      @include x-767-tablet {
        text-align: center;
      }
    }
  }
  .accordianContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    padding: 3.1rem 0;
    position: relative;

    .commonAccordian {
      border: 0.2rem solid #fff;
      transition: all 0.3s ease;
      border-radius: 1.2rem;
      background: #ffffff;
      margin: 0.6rem 0;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.1);
      .accordianBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1.2rem;
        cursor: pointer;
        height: 5.5rem;
        padding: 0 2.5rem;
        transition: height 0.5s ease;
        .acc-title {
          font-size: 1.8rem;
          font-weight: 500;
          text-align: left;
          color: #656565;
          margin-bottom: 0;
          width: 80%;
          line-height: 1.5;
          @include d-desktop {
            font-size: 1.6rem;
          }
        }

        span {
          display: flex;
          width: 2.6rem;
          height: 2.6rem;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          transition: transform 0.3s linear;
          border-radius: 100%;
          box-shadow: 0 0 11px -4px #b0b0b0;
          svg {
            height: 1.4rem;
            path {
              stroke: #01557d;
            }
          }
        }
      }
      .accordianContent {
        transition: height 0.8s linear;
        height: 0px;
        overflow: hidden;
        .accordianText {
          padding: 0 2.5rem 2.5rem 2.5rem;
          p {
            font-size: 1.6rem;
            font-weight: 400;
            color: #656565;
            margin-bottom: 0;
            @include d-desktop {
              font-size: 1.4rem;
            }
          }
        }
      }
      &.active {
        border-color: #01557d;
        .accordianArrow {
          transform: rotate(90deg);
          background-color: #01557d;
          svg {
            path {
              stroke: white;
            }
          }
        }
        .accordianContent {
          height: auto;
        }
      }
    }
  }
}
