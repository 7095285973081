@import "../../assets/scss/media";
.slider {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
  @include x-767-tablet{
    height: 28rem;
  }
}

.overlay {
  width: 37%;
  position: absolute;
  left: 7%;
  @include d-desktop{
    width: 45%;
  }
  @include md-tablet{
    width: 60%;
  }
  @include x-767-tablet{
    width: 90%;
  }
  .mainText {
    font-size: 5rem;
    font-weight: 700;
    color: #fff;
    @include d-desktop{
      font-size: 4rem;
    }
    @include x-767-tablet{
      font-size: 2.4rem;
    }
    span {
      color: #ffa700;
    }
  }
  .subText {
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 2rem;
    @include d-desktop{
      font-size: 1.4rem;
    }
    span {
      font-size: 2rem;
      font-weight: 700;
      color: #ffa700;
      @include d-desktop{
        font-size: 1.6rem;
      }
    }
  }
}

.dots {
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #135257;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #ffa700;
}
