#newsletterSection {
  margin-bottom: 11rem;
}
@media (max-width: 834px) {
  #newsletterSection {
    margin: 0 2rem 11rem;
  }
}
@media (max-width: 767px) {
  #newsletterSection {
    margin: 0 0rem 4rem;
    padding: 0rem 1rem 0rem;
  }
}
#newsletterSection .bgWrpper {
  background-image: url(../../assets/images/newsletterBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 26.1rem;
  border-radius: 0.5rem;
  position: relative;
  padding: 3rem 6rem;
}
@media (max-width: 767px) {
  #newsletterSection .bgWrpper {
    padding: 3rem 1rem;
  }
}
#newsletterSection .bgWrpper .leftBox {
  width: 44%;
}
@media (max-width: 1024px) {
  #newsletterSection .bgWrpper .leftBox {
    width: 60%;
  }
}
@media (max-width: 767px) {
  #newsletterSection .bgWrpper .leftBox {
    width: 100%;
  }
}
#newsletterSection .bgWrpper .leftBox .sectionTitle {
  color: #fff;
  margin-bottom: 1rem;
  line-height: 1.4;
}
@media (max-width: 767px) {
  #newsletterSection .bgWrpper .leftBox .sectionTitle {
    text-align: center;
  }
}
#newsletterSection .bgWrpper .leftBox form {
  margin-bottom: 1.5rem;
}
#newsletterSection .bgWrpper .leftBox form .inputWrap {
  position: relative;
}
#newsletterSection .bgWrpper .leftBox form .inputWrap input {
  height: 4.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #555555;
}
#newsletterSection .bgWrpper .leftBox form .inputWrap input:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}
#newsletterSection .bgWrpper .leftBox form .inputWrap button {
  position: absolute;
  right: 0;
  top: 0;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 3rem;
  background-color: #ffa700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  border: 0;
}
#newsletterSection .bgWrpper .leftBox .commonPara {
  color: #d9d9d9;
}
@media (max-width: 767px) {
  #newsletterSection .bgWrpper .leftBox .commonPara {
    text-align: center;
  }
}/*# sourceMappingURL=newslwtter.css.map */