form {
  position: relative;
  width: 100%;
}
form .comboInputWrap {
  position: relative;
}
@media (max-width: 1024px) {
  form .comboInputWrap {
    display: none;
  }
}
form .comboInputWrap .form-select {
  height: 4.5rem;
  border: 0;
  position: absolute;
  left: 0;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
}
form .comboInputWrap .form-select:focus {
  outline: none;
  box-shadow: none;
}
form .comboInputWrap .deviderLine {
  position: absolute;
  right: 60%;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 0.1rem;
  background-color: #cbcbcb;
}
@media (max-width: 1024px) {
  form .comboInputWrap .deviderLine {
    display: none;
  }
}
form .comboInputWrap .form-control {
  height: 4.5rem;
  border: 0.2rem solid #007078;
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
}
form .comboInputWrap .form-control:focus {
  outline: none;
  box-shadow: none;
}
form .searchBtn {
  position: absolute;
  right: 0;
  top: 0;
  height: 4.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 3rem;
  background-color: #007078;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  border: 0;
}
@media (max-width: 1024px) {
  form .searchBtn {
    display: none;
  }
}/*# sourceMappingURL=inputSelectCombo.css.map */