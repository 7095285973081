.navbar {
  width: 100%;
}
.navbar .navbar-toggler {
  border: 0;
  background: transparent;
  width: 3.8rem;
  height: 3.8rem;
}
.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.custNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
.custNav .navLeftBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 834px) {
  .custNav .navLeftBox .navbar-brand img {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .custNav .navLeftBox .navbar-brand img {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .custNav .col-md-3 {
    width: 33.333%;
  }
}
@media (max-width: 834px) {
  .custNav .col-md-3 {
    width: 45%;
  }
}
@media (max-width: 767px) {
  .custNav .col-md-3 {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .custNav .col-md-5 {
    display: none;
  }
}
.custNav .navbar-collapse .navbar-nav {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .custNav .navbar-collapse .navbar-nav {
    margin: 0 0 auto auto;
    width: 60%;
  }
}
@media (max-width: 834px) {
  .custNav .navbar-collapse .navbar-nav {
    width: 100%;
  }
}
.custNav .navbar-collapse .navbar-nav .nav-item.cartMenu::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 4%;
  width: 0.2rem;
  height: 55%;
  background: #cbcbcb;
}
@media (max-width: 1024px) {
  .custNav .navbar-collapse .navbar-nav .nav-item.cartMenu::after {
    right: 5.2%;
  }
}
@media (max-width: 991px) {
  .custNav .navbar-collapse .navbar-nav .nav-item.cartMenu::after {
    content: none;
  }
}
.custNav .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 1.6rem;
  font-weight: 500;
  color: #919191;
  position: relative;
  padding: 0;
}
@media (max-width: 1024px) {
  .custNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 1.4rem;
  }
}
.custNav .navbar-collapse .navbar-nav .nav-item .nav-link .cartBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: #007078;
  border-radius: 5rem;
  position: relative;
}
.custNav .navbar-collapse .navbar-nav .nav-item .nav-link .dotSpan {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 5rem;
  background: #27ed8e;
}

.allProductsDisplayBox {
  background-color: #f8f8f8;
  position: absolute;
  width: 90.5%;
  height: 53.9rem;
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: 0px 0px 6px -2px #000;
}
@media (max-width: 834px) {
  .allProductsDisplayBox {
    width: 100%;
    height: 100%;
    min-height: 50rem;
    overflow: auto;
  }
}
.allProductsDisplayBox .tabsWrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 767px) {
  .allProductsDisplayBox .tabsWrap {
    flex-direction: column;
    padding-top: 4rem;
  }
}
.allProductsDisplayBox .tabsWrap .closeBtn {
  position: absolute;
  right: 2.1rem;
  top: 1rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.allProductsDisplayBox .tabsWrap .nav-pills {
  width: 20%;
  height: 100%;
  max-height: 53.9rem;
  overflow-y: auto;
  gap: 0.5rem;
}
@media (max-width: 834px) {
  .allProductsDisplayBox .tabsWrap .nav-pills {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .allProductsDisplayBox .tabsWrap .nav-pills {
    width: 100%;
  }
}
.allProductsDisplayBox .tabsWrap .nav-pills::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.allProductsDisplayBox .tabsWrap .nav-pills::-webkit-scrollbar-thumb {
  background: #007078;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.allProductsDisplayBox .tabsWrap .nav-pills::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #e3a835, #e3a835 1px, #e3a835 0, #e3a835);
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.allProductsDisplayBox .tabsWrap .nav-pills::-webkit-scrollbar-corner {
  background: #e3a835;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
  height: 7.7rem;
  border-radius: 0;
  border: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
  position: relative;
  background-color: #ebebeb;
}
@media (max-width: 834px) {
  .allProductsDisplayBox .tabsWrap .nav-pills .nav-link {
    font-size: 1.8rem;
    height: 5.5rem;
    justify-content: center;
  }
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.6rem;
  height: 100%;
  background-color: #dddddd;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link.active {
  background-color: #007078;
  color: #f8f8f8;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link.active::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.6rem;
  height: 100%;
  background-color: #ffa700;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link:hover {
  background-color: #007078;
  color: #fff;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link:hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.6rem;
  height: 100%;
  background-color: #ffa700;
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
}
@media (max-width: 767px) {
  .allProductsDisplayBox .tabsWrap .nav-pills .nav-link span {
    display: none;
  }
}
.allProductsDisplayBox .tabsWrap .nav-pills .nav-link span img {
  height: 6rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.allProductsDisplayBox .tabsWrap .tab-content {
  width: 80%;
  height: 100%;
  padding: 2rem 3rem 0 1rem;
  max-height: 53.9rem;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .allProductsDisplayBox .tabsWrap .tab-content {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }
}
.allProductsDisplayBox .tabsWrap .tab-content::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.allProductsDisplayBox .tabsWrap .tab-content::-webkit-scrollbar-thumb {
  background: #007078;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.allProductsDisplayBox .tabsWrap .tab-content::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #e3a835, #e3a835 1px, #e3a835 0, #e3a835);
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25), inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}
.allProductsDisplayBox .tabsWrap .tab-content::-webkit-scrollbar-corner {
  background: #e3a835;
}
.allProductsDisplayBox.show {
  left: 50%;
  transition: left 0.4s ease;
}
.allProductsDisplayBox.hide {
  left: -50%;
  transition: left 0.4s ease;
}

@media (max-width: 991px) {
  #navbarTogglerDemo02.navbar-collapse {
    background: #171a26;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    display: block;
    height: 100%;
    left: -100%;
    overflow-y: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 30px;
    position: fixed;
    top: 0;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
    width: 50%;
    z-index: 999999;
  }
}
@media (max-width: 991px) {
  #navbarTogglerDemo02.navbar-collapse {
    width: 80%;
  }
}
#navbarTogglerDemo02.navbar-collapse.show {
  left: 0;
}
#navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item {
  padding-bottom: 1.5rem;
}
#navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item:last-child {
  border-bottom: 0;
}
#navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item .nav-link {
  justify-content: space-between;
  font-size: 2rem;
  color: unset;
  position: relative;
}
#navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item .nav-link:hover {
  color: #2276b9;
}
@media (max-width: 991px) {
  #navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
  }
}
@media (max-width: 991px) {
  #navbarTogglerDemo02.navbar-collapse.show .navbar-nav .nav-item .nav-link {
    color: #fff;
  }
}

.pagination {
  padding: 2rem 0;
  justify-content: flex-end;
  gap: 1rem;
}
.pagination button {
  background-color: rgba(0, 112, 120, 0.8156862745);
  color: #f8f8f8;
  padding: 0.5rem 1.5rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.pagination button.active {
  background-color: #004f54;
  color: #f8f8f8;
}
.pagination button:hover {
  background-color: #007078;
  color: #f8f8f8;
}/*# sourceMappingURL=navbar.css.map */