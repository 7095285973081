@import "../../assets/scss/media";
#newsletterSection {
  margin-bottom: 11rem;
  @include md-tablet {
    margin: 0 2rem 11rem;
  }
  @include x-767-tablet {
    margin: 0 0rem 4rem;
    padding: 0rem 1rem 0rem;
  }
  .bgWrpper {
    background-image: url(../../assets/images/newsletterBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 26.1rem;
    border-radius: 0.5rem;
    position: relative;
    padding: 3rem 6rem;
    @include x-767-tablet {
      padding: 3rem 1rem;
    }
    .leftBox {
      width: 44%;
      @include d-desktop {
        width: 60%;
      }
      @include x-767-tablet {
        width: 100%;
      }
      .sectionTitle {
        color: #fff;
        margin-bottom: 1rem;
        line-height: 1.4;
        @include x-767-tablet {
          text-align: center;
        }
      }

      form {
        margin-bottom: 1.5rem;
        .inputWrap {
          position: relative;
          input {
            height: 4.5rem;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: #555555;
            &:focus {
              outline: none;
              box-shadow: none;
              border-color: transparent;
            }
          }
          button {
            position: absolute;
            right: 0;
            top: 0;
            height: 4.5rem;
            width: -moz-fit-content;
            width: fit-content;
            padding: 1rem 3rem;
            background-color: #ffa700;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5rem;
            font-size: 1.4rem;
            font-weight: 500;
            border: 0;
          }
        }
      }

      .commonPara {
        color: #d9d9d9;
        @include x-767-tablet {
          text-align: center;
        }
      }
    }
  }
}
