#expertiseSection {
  background-color: #007078;
  padding: 8rem 0;
}
@media (max-width: 767px) {
  #expertiseSection {
    padding: 4rem 0;
  }
}
#expertiseSection .sectionTitle {
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
}
#expertiseSection .commonPara {
  text-align: center;
  width: 35%;
  margin: 0 auto;
  color: #d9d9d9;
}
@media (max-width: 767px) {
  #expertiseSection .commonPara {
    width: 100%;
  }
}
#expertiseSection .expertiseGrid {
  margin-top: 3rem;
}
#expertiseSection .expertiseGrid .parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2rem;
}
@media (max-width: 767px) {
  #expertiseSection .expertiseGrid .parent {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
}
#expertiseSection .expertiseGrid .parent .gridBox {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  overflow: hidden;
}
#expertiseSection .expertiseGrid .parent .gridBox::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 0.4rem;
}
#expertiseSection .expertiseGrid .parent .gridBox .overlayText {
  position: absolute;
}
#expertiseSection .expertiseGrid .parent .gridBox .overlayText h3 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1.5rem;
}
@media (max-width: 834px) {
  #expertiseSection .expertiseGrid .parent .gridBox .overlayText h3 {
    font-size: 2rem;
  }
}
#expertiseSection .expertiseGrid .parent .gridBox .overlayText p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 834px) {
  #expertiseSection .expertiseGrid .parent .gridBox .overlayText p {
    font-size: 1.2rem;
  }
}
#expertiseSection .expertiseGrid .div1 {
  grid-area: 1/1/2/2;
  background-image: url(../../assets/images/egrid1.png);
  height: 18.9rem;
}
@media (max-width: 767px) {
  #expertiseSection .expertiseGrid .div1 {
    grid-area: 1/1/2/2;
  }
}
#expertiseSection .expertiseGrid .div1::before {
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, rgb(255, 167, 0) 0%, rgba(16, 12, 8, 0.2414215686) 80%);
}
#expertiseSection .expertiseGrid .div1 .overlayText {
  bottom: 15%;
  width: 80%;
  left: 5%;
  z-index: 1;
}
#expertiseSection .expertiseGrid .div2 {
  grid-area: 2/1/3/2;
  background-image: url(../../assets/images/egrid2.png);
}
@media (max-width: 767px) {
  #expertiseSection .expertiseGrid .div2 {
    grid-area: 2/1/3/2;
  }
}
#expertiseSection .expertiseGrid .div2::before {
  right: 0;
  width: 100%;
  background: linear-gradient(90deg, #ffa700 0%, rgba(16, 12, 8, 0.2392156863) 80%);
  transform: rotate(-180deg);
}
#expertiseSection .expertiseGrid .div2 .overlayText {
  bottom: 15%;
  width: 80%;
  right: 5%;
  z-index: 1;
  text-align: right;
}
#expertiseSection .expertiseGrid .div3 {
  grid-area: 1/2/3/3;
  background-image: url(../../assets/images/egrid3.png);
}
@media (max-width: 767px) {
  #expertiseSection .expertiseGrid .div3 {
    grid-area: 3/1/4/2;
  }
}
#expertiseSection .expertiseGrid .div3::before {
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, rgb(255, 167, 0) 0%, rgba(0, 0, 0, 0) 75%);
}
#expertiseSection .expertiseGrid .div3 .overlayText {
  bottom: 7%;
  width: 85%;
  right: 10%;
  z-index: 1;
}/*# sourceMappingURL=expertise.css.map */