@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "./mixin";
@import "./media";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: #007078;
    border-radius: 16px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      90deg,
      #e3a835,
      #e3a835 1px,
      #e3a835 0,
      #e3a835
    );
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  }
  &::-webkit-scrollbar-corner {
    background: #e3a835;
  }
}
body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  &.active {
    overflow: hidden;
  }
}
ul {
  padding: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
button {
  color: #1d1d1f;
  padding: 0;
  margin-bottom: 0;
}
button {
  border: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
p,
span,
strong,
b,
a,
li,
input {
  color: #5d5d5d;
  padding: 0;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 670px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 882px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1095px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

@include commonSlider;
@include commonTitle;
@include commonPara;
@include commonSpanBox;
// .row{
//   width: 100%;
// }
#popularProductsSection {
  padding: 8rem 0 10rem;
  background-color: #f8f8f8;
  @include md-tablet {
    padding: 8rem 2rem 10rem;
  }
  @include x-767-tablet {
    padding: 4rem 1rem 5rem;
  }
  .sectionTitle {
    margin-bottom: 1rem;
    @include x-767-tablet {
      text-align: center;
    }
  }
  .commonPara {
    width: 45%;
    @include x-767-tablet {
      width: 100%;
      // text-align: center;
    }
  }
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-top: 3rem;
    @include x-767-tablet {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
    .gridBox {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
      position: relative;
      padding: 2rem;
      border-radius: 0.6rem;
    }

    .wrap {
      h4 {
        font-size: 2rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 0.5rem;
        @include d-desktop {
          font-size: 1.6rem;
        }
      }
      &.pose3 {
        position: absolute;
        top: 10%;
        right: 5%;
        height: 80% !important;
        .commonPara {
          width: 100% !important;
        }
      }
      &.pose1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .commonPara {
          width: 70%;
          color: #d9d9d9;
          @include d-desktop {
            font-size: 1.2rem;
          }
        }
      }
    }

    .div1 {
      grid-area: 1 / 1 / 3 / 2;
      background-image: url(../../assets/images/grid1.png);
      height: 40.1rem;
      @include x-767-tablet {
        grid-area: 1 / 1 / 2 / 2;
        height: 22rem;
      }
    }
    .div2 {
      grid-area: 1 / 2 / 2 / 3;
      background-image: url(../../assets/images/grid2.png);
      @include x-767-tablet {
        grid-area: 2 / 1 / 3 / 2;
      }
    }
    .div3 {
      grid-area: 2 / 2 / 3 / 3;
      background-image: url(../../assets/images/grid3.png);
      @include x-767-tablet {
        grid-area: 3 / 1 / 4 / 2;
      }
    }
  }
}

#bestDealSection {
  padding: 0rem 0 11rem;
  @include md-tablet {
    padding: 0rem 2rem 11rem;
  }
  @include x-767-tablet {
    padding: 0rem 1rem 0rem;
  }
  .dealWrap {
    background-image: url(../../assets/images/bestdealBg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 22.6rem;
    width: 100%;
    position: relative;
    padding: 2rem 6rem;
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include x-767-tablet {
      padding: 2rem 2rem;
    }
    h5 {
      font-size: 3.64rem;
      font-weight: 700;
      color: #fff;
      margin-bottom: 0.5rem;
      width: 45%;
      @include d-desktop {
        width: 55%;
        font-size: 3rem;
      }
      @include x-767-tablet {
        width: 100%;
        font-size: 2.2rem;
      }
    }
    p {
      font-size: 2.2rem;
      font-weight: 400;
      color: #ffa700;
      @include d-desktop {
        font-size: 2rem;
      }
    }
  }
}

.emptyPage {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#filterPage {
  padding: 6rem 0;
  @include x-767-tablet{
    padding: 2rem 0 4rem;
  }
  @include commonSelect;
  .row {
    @include md-tablet {
      row-gap: 1.5rem;
    }
  }
  .filter-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 4rem 0 4rem;
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid #ccc;
    @include md-tablet {
      margin: 4rem 0 2rem;
      padding-bottom: 2rem;
    }
    .filterTitle {
      height: 4.5rem;
      display: flex;
      align-items: center;
      p {
        @include commonPara;
      }
      .commonPara {
        font-size: 1.8rem;
        font-weight: 600;

        @include md-tablet {
          font-size: 1.6rem;
        }
        @include x-767-tablet {
          font-size: 1.2rem;
        }
      }
    }
    .menusWraps {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      height: 4.5rem;
      @include x-767-tablet {
        gap: 0.5rem;
        height: 3.5rem;
      }
      .menuBoxItem {
        font-size: 1.6rem;
        font-weight: 500;
        color: #5b5b5b;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.5rem;
        width: fit-content;
        padding: 0 1rem;
        border-radius: 0.4rem;
        cursor: pointer;
        @include md-tablet {
          font-size: 1.4rem;
        }
        @include x-767-tablet {
          font-size: 1.2rem;
          height: 3.5rem;
        }
        &:hover {
          background-color: #007078;
          color: #fff;
        }
        &.active {
          background-color: #007078;
          color: #fff;
        }
      }
    }
    .allBtn {
      display: flex;
      align-items: center;
      height: 4.5rem;
      width: fit-content;
      padding: 0 1rem;
      font-size: 1.8rem;
      font-weight: 600;
      cursor: pointer;
      @include md-tablet {
        font-size: 1.4rem;
      }
      .de {
        @include x-767-tablet {
          display: none;
        }
      }
    }
  }
  .filterdItemsListing {
    .ddd {
      position: relative;
    }
  }
  .selectedMenusWrap {
    border-bottom: 0.1rem solid #ccc;
    margin-bottom: 4rem;
    .selectedMenusRows {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      margin-bottom: 4rem;
      @include md-tablet {
        margin-bottom: 2rem;
      }
      .selectedBox {
        font-size: 1.6rem;
        font-weight: 500;
        color: #5b5b5b;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.5rem;
        width: fit-content;
        padding: 0 1rem;
        border-radius: 0.4rem;
        background-color: #007078;
        color: #fff;
        @include md-tablet {
          font-size: 1.4rem;
        }
        @include x-767-tablet {
          font-size: 1.2rem;
          height: 3.5rem;
        }
      }
      .clearBtn {
        display: flex;
        align-items: center;
        height: 4.5rem;
        width: fit-content;
        padding: 0 1rem;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0 1rem;
        border-radius: 0.4rem;
        cursor: pointer;
        background-color: red;
        color: #fff;
        gap: 1rem;
        @include md-tablet {
          font-size: 1.4rem;
        }
        @include x-767-tablet {
          font-size: 1.2rem;
          height: 3.5rem;
        }
        i {
          @include md-tablet {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

#mainProductListSection {
  padding-top: 8rem;
  .sectionTitle {
    text-align: center;
  }
  .row {
    row-gap: 1.5rem;
  }
  .wraps {
    border: 0.1rem solid #007078;
    border-radius: 0.5rem;
    background-color: #eef7ff;
    padding: 1rem;
    // .typeImag {
    //   height: 22rem;
    //   overflow: hidden;
    //   img {
    //     object-fit: cover;
    //     height: 100%;
    //     width: 100%;
    //   }
    // }
    .typeText {
      padding-top: 1.2rem;
      h6 {
        font-size: 1.8rem;
        color: #1c2c2d;
        font-weight: 600;
        margin-bottom: 0.5rem;
        text-align: center;
      }
    }
  }
}
