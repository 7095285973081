@import '../../assets/scss/media';
form {
  position: relative;
  width: 100%;
  .comboInputWrap {
    position: relative;
    @include d-desktop{
      display: none;
    }
    .form-select {
      height: 4.5rem;
      border: 0;
      position: absolute;
      left: 0;
      width: fit-content;
      background-color: transparent;
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: 400;
      color: #000;
      
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .deviderLine {
      position: absolute;
      right: 60%;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      width: 0.1rem;
      background-color: #cbcbcb;
      @include d-desktop{
        display: none;
      }
    }
    .form-control {
      height: 4.5rem;
      border: 0.2rem solid #007078;
      padding-left: 1.5rem;
      border-radius: .5rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #000;
     
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .searchBtn{
    position: absolute;
    right: 0;
    top: 0;
    height: 4.5rem;
    width: fit-content;
    padding: 1rem 3rem;
    background-color: #007078;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    font-size: 1.4rem;
    font-weight: 500;
    border: 0;
    @include d-desktop{
      display: none;
    }
  }
}
